// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #000000;
$warning: #d4c251;
$success: #477d60;
$danger: #733049;

// override main menu colors
$menu-bg: #f2f2f2;
$menu-dropdown-bg: $menu-bg;

$menu-sm-bg: $menu-bg; // mobile main menu header bg color
$menu-sm-fg: $dark; // mobile main menu header fg color

// override Campaign outlet button color
$outlet-btn-color: #f98727;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
// $buygift-btn-color: $dark;

// override route links color
$link-color: #9e4276;

$footer: $menu-bg;
$footer-brand: #1d1c1c;
.footer-links-column {
  border-right: 1px solid #f8f9fa36 !important;
}

// override product subcategory color
$product-subcategory-bg: transparent;
$product-subcategory-fg: $dark;
$product-subcategory-border: 1px solid $secondary;
$hover-decoration: false;

$shopping-cart-alert-warning-bg: #fff3cd; // empty shopping cart alert background
$shopping-cart-alert-warning-fg: #000000; // empty shopping cart alert color
$shopping-cart-alert-warning-border: transparent; // empty shopping cart alert border color
$shopping-cart-item-btn-remove: #dc3545; // shopping cart item modal dialog remove button
$shopping-cart-item-btn-cancel: #6c757d; // shopping cart item modal dialog cancel button
$shopping-cart-item-old-price: #dc3545; // shopping cart item old price color

$load-more-button-bg: $gray-600; // infinite scroll component load more button background

.dropdown-menu {
  border-radius: none;
}

.navbar-brand {
  padding: 0.8rem;
}

// override product discount ribbon color
$discount-ribbon-bg: #383a40;
$discount-ribbon-fg: $white;

// override assembly-page card-deck-overlay color
$card-deck-overlay-bg: #954875;
// disable the overlay opacity
$card-deck-opaque-overlay: false;

// override product-serie-list-item color
$product-series-summary-bg: $menu-bg;
$product-series-summary-fg: $dark;
$product-series-button-bg: $outlet-btn-color;

.valentines {
  background-color: #df7899 !important;
}

.monthly {
  background-color: #b16a94 !important;
}

.cybermonday {
  background-color: #071249 !important;
  color: #ff01ba !important;
}

.greybox {
  background-color: #ebe9e9;
}


.page-builder-body {
  // define element styles for custom pages
}

